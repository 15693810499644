import React, { useState } from "react";
import styled from "styled-components";
import { useTable, useSortBy } from "react-table";
import { format } from 'date-fns';
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Container, Stack, TextField, Typography } from "@mui/material";
import { setToken } from "../services/token";

const base_url = 'https://admin.whypn.com' //"http://localhost:8181"
const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid lightgrey;
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th{
      background: #1976d2;
      color: white
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid lightgrey;
      border-right: 1px solid lightgrey;

      :last-child {
        border-right: 0;
      }

    }
  }
`;

function Table({ columns, data, update }: { columns: any, data: any, update: any }) {
  // Use the state and functions returned from useTable to build your UI
  const [sortBy, setsortBy] = useState([])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

  } = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  React.useEffect(() => {
    console.log("sort");
  }, [sortBy]);


  // Render the UI for your table
  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={update}
      hasMore={true}
      loader={<h4>Loading...</h4>}
    >
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </InfiniteScroll>
  );
}


function TransactionTable(props: any) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    credentials: "include"
  };

  React.useEffect(() => {
    fetch(base_url + `/transaction?page=0&size=${size}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      credentials: "include"
    })
      .then(response => {
        if (response.status == 401) {
          props.setToken(false)
          return;

        }
        return response.json()
      })
      .then(data => {
         
        setItems(data)
      }
      );
  }, [])

  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [isAll, setIsALl] = useState(false);
  const [size, setSize] = useState(40);
  const [userId, setUserId] = useState("");
  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'Id', // accessor is the "key" in the data
      },
      {
        Header: 'Пользователь',
        accessor: 'UserId',
      },
      {
        Header: 'Время (UTC+0)',
        accessor: 'Time',
        Cell: ((value: any) => {

          var v = value.cell.value
          var a  = new Date(v).toISOString().
          replace(/T/, ' ').      // replace T with a space
          replace(/\..+/, '') 
      
         /*  var TheDate = new Date( Date.UTC(2012, 10, 5) );
          var a = format(new Date(v), 'dd.MM.yyyy kk:mm:ss') */
          return a
        }),
      },
      {
        Header: 'Тип',
        accessor: 'Type',
      },
      {
        Header: 'Сумма до',
        accessor: 'TotalBefore',
      },
      {
        Header: 'Сумма после',
        accessor: 'TotalNow',
      },
    ],
    []
  )

  const fetchMoreData = () => {
    setTimeout(() => {
      if (userId == "") {
        fetch(base_url + `/transaction?page=${page + 1}&size=${size}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          credentials: "include"
        })
          .then(response => {
            if (response.status == 401) {
              props.setToken(false)
              return;

            }
            return response.json()
          })
          .then(data => {
             


            setItems(items.concat(data));
            setPage(page + 1)
            //  setItems(data)
          }
          );
      }
      else {
         
        if (!isAll) {
          checkWithFilter();
        }
      }

    }, 2);
  };


  var checkWithFilter = () => {
    fetch(base_url + `/transaction?page=${page + 1}&size=${size}&userId=${userId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      credentials: "include"
    })
      .then(response => {
        if (response.status == 401) {
          props.setToken(false)
          return;

        }
        return response.json()
      })
      .then(data => {
         

        if (data.length > 0) {
          setItems(items.concat(data));

          setPage(page + 1)
          if (data.length < size)
            setIsALl(true)
          //  setItems(data)
        }
        else {
          setIsALl(true)
        }
      }

      );

  }
  const data = React.useMemo(() => items, [items]);

  var userFilter = () => {
    fetch(base_url + `/transaction?page=${0}&size=${size}&userId=${userId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      credentials: "include"
    })
      .then(response => {
         
        if (response.status == 401) {
          props.setToken(false)
          return;

        }
        return response.json()
      })
      .then(data => {
         

        setIsALl(false)
        setItems(data);
        setPage(data.length >= 40 ? 1 : 0)
        //  setItems(data)
      }
      );

  }

  const handleUserId = (event: React.ChangeEvent<HTMLInputElement>) => {

    setUserId(event.target.value);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h3" gutterBottom>
        WhyPN // Admin
      </Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 1, md: 1 }}
        style={{ padding: '1em' }}
      >
        <TextField id="user_id" label="Чат" variant="outlined" onChange={handleUserId} size="small" />
        <Button variant="contained" onClick={userFilter}>Найти</Button>
      </Stack>

      <Styles>

        <Table columns={columns} data={data} update={fetchMoreData} />
      </Styles>
    </Container>
  );
}

export default TransactionTable;
