import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert } from '@mui/material';
import { setToken } from '../services/token';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        WhyPN
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const base_url = "https://admin.whypn.com" //"http://localhost:8181"
const theme = createTheme();

const get_set_cookies = function (headers: any) {
  const set_cookies = []
  for (const [name, value] of headers) {
    if (name === "set-cookie") {
      set_cookies.push(value)
    }
  }
  return set_cookies
}

export default function SignIn(props: any) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  var onLoginClick = () => {
    /*  const requestOptions = {
       method: 'POST',
       headers: { "Content-Type": "application/json" },
       body: JSON.stringify({ Username: 'admin', Password: 'password' })
     }; */
    // Simple POST request with a JSON body using fetch
     
    var p = JSON.stringify({ username: "1", password: "1" })
    /*  const requestOptions = {
       method: 'POST',
       headers: {      'Content-Type': 'application/x-www-form-urlencoded' },
       body:p
     };
     fetch('https://admin.whypn.com/login', requestOptions)
       .then(response => response.json())
       .then(data => {
 
       });
  */

    axios.post(base_url+'/login', {
      Username: email, Password: password
    }, { withCredentials: true })
      .then(function (response: any) {
         
        if (response.status = "200") {

          //const set_cookies = get_set_cookies(response.headers)
          props.setToken(true)
          setToken(response.data)
        }
        else {
         
          props.setToken(false)
          setIsAuthError(true)
        }
      })
      .catch(function (error) {
        setIsAuthError(true)
        console.log(error);
      });

    /*  fetch("https://admin.whypn.com/login",
       {
         method: 'POST',
         credentials: true,
         headers: {
           'Content-Type': 'application/json',
           'Accept': 'application/json'
         },
         
       
         body: JSON.stringify({ Username: email, Password: password })
       }).then((response) => {
          
         if (response.ok) {
           const set_cookies = get_set_cookies(response.headers)
           props.setToken(true)
         }
         else {
           setIsAuthError(true)
           props.setToken(false)
         }
       })
  */
  }
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isAuthError, setIsAuthError] = React.useState(false);

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={require('../auth/assets/wp.jpg')} width={50} />
          {/*  <Avatar  src="/assets/wp.jpg" /> */}
          <Typography component="h1" variant="h5">
            WhyPN // Admin
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Почта"
              name="email"
              onChange={handleEmail}
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={handlePassword}
              name="password"
              label="Пароль"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/*  <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={onLoginClick}
            >
              Войти
            </Button>
            <Grid container>
              {/*  <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Зарегестироваться"}
                </Link>
              </Grid>
 */}
            </Grid>
            {isAuthError
              ? <Alert severity="error">Неверный логин или пароль</Alert>
              : <div></div>
            }
          </Box>

        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}