import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import TransactionTable from './transactions/TransactionTable';
import Login from './auth/Login';
import { getToken } from './services/token';

function App() {

  React.useEffect(() => {
     
    var res = getToken().then((r: any) => {
      if (res != undefined)
        setToken(true)
    })

  }, []);


  const [token, setToken] = useState(false);
  if (!token) {
    return (
      <Login setToken={setToken}></Login>
    )
  }
  else {
    return (
      <div className="App">
        <TransactionTable setToken={setToken}></TransactionTable>
      </div>
    );
  }
}

export default App;
